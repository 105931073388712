@import (reference) '../../../styles/globals.less';

.skip-links {
  position: relative;
  width: 100%;
  overflow: hidden;

  &__forwards {
    position: absolute;
    top: @spacing-8px;
    left: 50%;
    transform: translate3d(-50%, -150%, 0);
    transition: transform (0.75 * @transition-speed) @transition-easing;

    &:focus-within {
      transform: translate3d(-50%, 0, 0);
    }

    // TODO: this is only necessary because of inheritance
    // from Vhols theme
    a {
      &,
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__backwards {
    position: absolute;
    bottom: @spacing-8px;
    left: 50%;
    transform: translate3d(-50%, 150%, 0);
    transition: transform (0.75 * @transition-speed) @transition-easing;

    &:focus-within {
      transform: translate3d(-50%, 0, 0);
    }
  }
}
