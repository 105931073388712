@import (reference) '../../../styles/globals.less';

@height-default: 344px;
@height-sm: 344px;
@height-md: 407px;
@height-lg: 251px;
@height-xl: 332px;

.height-responsive() {
  height: @height-default;

  @media (min-width: 640px) and (max-width: 767px) {
    height: @height-sm;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: @height-md;
  }

  @media (min-width: 992px) and (max-width: 1279px) {
    height: @height-lg;
  }

  @media (min-width: 1280px) {
    height: @height-xl;
  }
}

.article-card-container {
  position: relative;

  &__grid,
  &__mosaic {
    &__gap {
      padding: @spacing-24px @spacing-16px @spacing-24px;

      &__has-cta {
        padding-bottom: @spacing-72px;
      }

      @media (min-width: 768px) and (max-width: 1279px) {
        padding-left: @spacing-24px;
        padding-right: @spacing-24px;
      }

      @media (min-width: 1280px) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: @spacing-32px;
        padding-top: @spacing-32px;
      }
    }
  }

  &__grid {
    background-color: var(--token-color-neutral-n10);
  }

  &__mosaic {
    &__gap {
      padding-top: 0;
    }
  }

  &__content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
  }

  &__small {
    @media (max-width: 639px) {
      height: 154px;
    }

    @media (min-width: 992px) {
      height: auto;
    }
  }

  &__large,
  &__middle {
    .height-responsive();
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: @spacing-16px;
    grid-row-gap: @spacing-16px;
    position: relative;
    transition: all 0.5s ease-in-out;
    @media (min-width: 768px) {
      grid-column-gap: @spacing-24px;
      grid-row-gap: @spacing-24px;
    }
  }

  &__cta {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    bottom: @spacing-24px;
    right: @spacing-16px;
    .see-all-button {
      margin-top: @spacing-16px;
      padding: 0;
      color: var(--token-color-transparent-contrast-8);
      border: none;
      border-radius: @spacing-4px;
      cursor: pointer;
      font-size: @spacing-16px;
      background-color: transparent;
      text-decoration: underline;
    }

    @media (min-width: 768px) {
      right: @spacing-24px;
    }

    @media (min-width: 992px) {
      right: @spacing-24px;
    }

    @media (min-width: 1280px) {
      right: 0;
    }
  }

  &__footer-cards {
    @media (min-width: 640px) {
      margin-top: @spacing-16px;
    }

    @media (min-width: 768px) {
      margin-top: @spacing-24px;
    }

    @media (min-width: 1280px) {
      margin-top: @spacing-24px;
    }

    &__desktop {
      &__footer-card {
        .height-responsive();
      }

      &__one {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: @spacing-24px;;
        grid-template-areas: 'cardone cardtwo';
      }

      &__two {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: @spacing-24px;;
        grid-template-areas: 'cardone cardtwo';
      }

      &__three {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        gap: @spacing-24px;;
        grid-template-areas: 'cardone cardone cardtwo cardthree';

        &__footercard-1 {
          grid-area: cardone;
        }
      }
    }

    &__tablet {
      &__one {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: @spacing-24px;
        grid-template-areas: 'cardone';
      }

      &__two {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: @spacing-16px;
        grid-template-areas: 'cardone' 'cardtwo';
        &__footercard-1 {
          grid-area: cardone;
        }
      }

      &__three {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: @spacing-16px;
        grid-template-areas:
          'cardone cardone'
          'cardtwo cardthree';

        &__footercard-1 {
          grid-area: cardone;
        }
      }

      @media (min-width: 768px) {
        &__two,
        &__three {
          gap: @spacing-24px;
        }
      }
    }
  }
}
