@import (reference) "../../../styles/globals.less";

.customError {
    padding: 0 @spacing-16px;

    & div[class*='heading-component_component'] {
        text-align: left;

        @media (min-width: @screen-sm-min) { // 767px
            text-align: center;
        }
    }

    & ol[class*='icon-panel-module_inner'] {
        padding: 0;
    }

    @media (min-width: @screen-sm-min) { // 767px
        padding: 0 @spacing-24px;
    }
}
