@import (reference) '../../../styles/globals.less';

.breadcrumbs {
  color: var(--token-color-text-secondary);
  button,
  span,
  a {
    font-size: @spacing-12px !important;
    color: var(--token-color-text-secondary);
  }

  svg {
    width: @spacing-8px !important;
    height: @spacing-8px !important;
  }

  &__button-component {
    margin-right: 6px;
  }

  &__clickable {
    text-decoration: underline;
    cursor: pointer;
  }

  &__list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    align-items: center;
    span {
      margin-left: 4px;
    }
  }

  &__current {
    color: var(--token-color-text-secondary);
    font-size: @spacing-12px;
  }
}
