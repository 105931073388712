@import (reference) '../../../styles/globals.less';

.loading {
    border: 1px solid var(--token-color-colour-border-minimal);
    background-color: #f4f4f4;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    padding: @spacing-20px;
    min-height: 545px;
}

.hotels-carousel {
    position: relative;
    padding: @spacing-48px 0 0 0;

    @media screen and (min-width: @screen-sm-min) {
        padding: @spacing-64px 0 0 0;
    }

    &:focus {
        outline: none;
    }

    & .viewAllHotels {
        display: flex;
        justify-content: center;
        padding: @spacing-24px 0 0 0;
    }
}