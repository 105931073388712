@import (reference) "../../../styles/globals.less";

.text-lockup {

    div[data-testid^='text-lockup-component'] {
        padding-bottom: @spacing-24px;
        padding-top: @spacing-48px;

        @media (min-width: @screen-sm-min) {

            & {
                padding-bottom: @spacing-32px;
                padding-top: @spacing-64px;
            }
        }
    }

    &.pb-none {

        @media (min-width: @screen-sm-min) {

            & div[data-testid^='text-lockup-component'] {
                padding-bottom: 0;
            }
        }
    }
}