@import (reference) '../../../styles/globals.less';

.google-map {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;

    & div[role='dialog']{
        padding: 0;

        & > div {
            overflow: hidden !important;

            @media (min-width: @screen-sm-min) {
                overflow: auto !important;
            }
        }

        & > div:first-child {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;

            & > button {
                display: none !important;
            }
        }
    }
}