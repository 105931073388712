@import (reference) '../../../styles/globals.less';

.spacer-main {
  width: 100%;
  margin: 0 auto;
  height: 48px;

  &--debug {
    background: linear-gradient(
      90deg,
      rgba(107, 107, 107, 1) 0%,
      rgba(210, 210, 210, 0) 100%
    );
    border-bottom: 1px solid crimson;
  }

  @media (min-width: 768px) {
    height: 64px;
  }

  @media (min-width: 992px) {
    height: 96px;
  }

  &--small {
    height: 48px;

    @media (min-width: 768px) {
      height: 64px;
    }
  }

}
