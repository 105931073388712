@import (reference) "../../../styles/globals.less";

.tailfin {

    //Override the default max-width and width values.
    & article[data-testid^='tailfin-hero-banner-module_content'] {
        max-width: @body-width;
        width: auto;
    }

    @media (min-width: @screen-sm-min) {

        & div[class^='tailfin-hero-banner-module_content-inner'] {
            grid-column: 1/span 6;
        }
    }
}