@import (reference) '../../../styles/globals.less';

.logo-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  padding: 32px 0;
  margin: 0 auto 56px auto;
  align-items: center;

  & .logo {
    width: 147px;
    height: 56px;
    position: relative;

    &.vaa {
      width: 180px;
      height: 42px;
    }
  }

  @media (min-width: @screen-sm-min) {
    margin: 0 auto 120px auto;
  }
}
