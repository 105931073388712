@import (reference) '../../../styles/globals.less';

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.hero-banner {
    display: grid;
    grid-template: 1fr / 1fr;
    place-items: center;

    &>* {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        height: 100%;
    }

    &-item {
        width: 100%;
        z-index: 1;

        &.fadeout {
            animation: fadeout 0.5s forwards;
        }

        &.fadein {
            animation: fadein 0.5s forwards;
        }

        &.active {
            z-index: 2;
        }

        &:not(.active) {
            display: block;
        }

        &>section {
            @media (min-width: @screen-md-min) {
                height: 100%;

                &>div[class*='_desktopImage_']:hover img {
                    transform: scale(1.03);
                }
            }
        }
    }
}