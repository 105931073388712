@import (reference) '../../../styles/globals.less';

.carousel {
  padding-bottom: @spacing-24px;

  & article[data-testid^='content-card-component'] {
    width: @width-100;

    & div[class*='_image_'] {
      min-height: auto;
      aspect-ratio: @image-aspect-ratio;
    }
  }

  @media (min-width: @screen-sm-min) {
    padding-bottom: @spacing-32px;
  }
}
