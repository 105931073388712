@import (reference) "../../../styles/globals.less";

.gridItem {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row: auto;

  &.full-width {
    max-width: @body-width;
    padding: 0 @spacing-16px;
    margin: auto;
    width: calc(@width-100 - @spacing-32px);
    box-sizing: content-box;

    @media (min-width: @screen-sm-min) { // 767px
      padding: 0 @spacing-24px;
      width: calc(@width-100 - @spacing-48px);
    }

    @media (min-width: @screen-md-min) { // 990px
      padding: 0 @spacing-40px;
      width: calc(@width-100 - @spacing-80px);
    }

    @media (min-width: @screen-xl-min) { // 1200px
      width: @width-100;
    }
  }

  // Margin bottom
  &.margin-bottom {
    margin-bottom: @spacing-32px;
  }

  // Span number of columns
  &.span-1 {
    grid-column-end: span 1;
  }

  &.span-2 {
    grid-column-end: span 2;
  }

  &.span-3 {
    grid-column-end: span 3;
  }

  &.span-4 {
    grid-column-end: span 4;
  }

  &.span-5 {
    grid-column-end: span 5;
  }

  &.span-6 {
    grid-column-end: span 6;
  }

  &.span-7 {
    grid-column-end: span 7;
  }

  &.span-8 {
    grid-column-end: span 8;
  }

  &.span-9 {
    grid-column-end: span 9;
  }

  &.span-10 {
    grid-column-end: span 10;
  }

  &.span-11 {
    grid-column-end: span 11;
  }

  &.span-12 {
    grid-column-end: span 12;
  }

  // Offset number of columns
  &.offset-0 {
    grid-column-start: 1;
  }

  &.offset-1 {
    grid-column-start: 2;
  }

  &.offset-2 {
    grid-column-start: 3;
  }

  &.offset-3 {
    grid-column-start: 4;
  }

  &.offset-4 {
    grid-column-start: 5;
  }

  &.offset-5 {
    grid-column-start: 6;
  }

  &.offset-6 {
    grid-column-start: 7;
  }

  &.offset-7 {
    grid-column-start: 8;
  }

  &.offset-8 {
    grid-column-start: 9;
  }

  &.offset-9 {
    grid-column-start: 10;
  }

  &.offset-10 {
    grid-column-start: 11;
  }

  &.offset-11 {
    grid-column-start: 12;
  }

  &.offset-12 {
    grid-column-start: 13;
  }

  &.grid-row-mob-auto {
    grid-row: auto;
  }

  each(range(1, 12, 1), {
    &.grid-row-mob-@{index} {
      grid-row: @value;
    }
  });

@media (min-width: @screen-sm-min) { // 767px
  &.grid-row-desk-auto {
    grid-row: auto;
  }

  each(range(1, 12, 1), {
    &.grid-row-desk-@{index} {
      grid-row: @value;
    }
  });
}
}