@import (reference) '../../../styles/globals.less';

.hotel-info-window {
    max-width: 300px;
    padding: 0;

    article[class*='hotels-map-card'] {

        & div[class^='hotel-card-module_image'] {
        
            img {
                width: 100%;
                height: auto;
            }
        }

        & div[class^='hotel-card-module_ratings'] {
            flex-direction: column;
            gap: @spacing-12px;

            @media (min-width: @screen-sm-min) {
                flex-direction: row;
            }
        }
    }
}

.hotel-info-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: @spacing-2px @spacing-16px;
}