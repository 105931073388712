@import (reference) '../../../styles/globals.less';

@animation-speed: 0.5s;
@route-map-z-index: 100000003;

@keyframes slideInDown {
    0% {
        transform: translate(0%, 100%);
        opacity: 0;
    }

    100% {
        transform: translate(0%, 0%);
        opacity: 1;
    }
}

@keyframes slideOutUp {
    0% {
        transform: translate(0%, 0%);
        opacity: 1;
    }

    100% {
        transform: translate(0%, -100%);
        opacity: 0;
    }
}

.map-enter {
    animation: slideInDown @animation-speed forwards;

    @media (min-width: @screen-sm-min) {
        animation: none;
    }
}

.map-exit {
    animation: slideOutUp @animation-speed forwards;

    @media (min-width: @screen-sm-min) {
        animation: none;
    }
}

.route-map {

    &__header {
        display: flex;
        align-items: center;
        gap: @spacing-8px;
        padding: @spacing-16px;
        justify-content: space-between;
        background-color: var(--token-color-colour-text-inverted-primary);
    }

    &__content {
        background-color: var(--token-color-colour-neutral-20);
        margin-top: @spacing-16px;

        @media (min-width: @screen-sm-min) {
            margin-top: @spacing-24px;
        }

        &.mobile-show {
            display: block;
        }
    }

    &__full-screen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: @route-map-z-index;
        margin-top: 0;

        @media (min-width: @screen-sm-min) {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            margin-top: @spacing-24px;
            z-index: auto;
        }
    }

    &__button {
        display: block;
        margin-top: @spacing-16px;

        @media (min-width: @screen-sm-min) {
            display: none;
            margin-top: @spacing-24px
        }
    }

    .mobile-show {

        @media (min-width: @screen-sm-min) {
            display: none;
        }
    }
}