@import (reference) '../../../styles/globals.less';

.image {
    width: 100%;
    padding: @spacing-16px 0;

    figure {
        position: relative;

        .caption {
            margin-top: @spacing-8px;

            &.popupTitle {
                opacity: 0;
                position: absolute;
                top: @spacing-12px;
                right: @spacing-12px;
                background-color: var(--token-color-neutral-n95);
                color: var(--token-color-neutral-n0);
                padding: @spacing-8px;
                margin: 0;
                border-radius: @spacing-4px;
                transition: opacity 0.3s ease;
            }
        }

        &:hover {
            .caption.popupTitle {
                opacity: 1;
            }
        }

    }
}