// @import (reference) '../../styles/styled-components.module.less';
@import (reference) "../../../styles/globals.less";
.root {
	// TODO: this will idelaly be calcualted off the height of header and alert banner (if it exists)
	@height-default: calc(100vh - @spacing-32px);

	@height-minimum: 600px;
	@height-minimum-mobile: 530px;
	@height-maximum: 850px;
	@zindex-content: 1;
	@zindex-tailfin: 1;
	@zindex-image: 1;

	@transition-speed: 0.7s;
	@transition-speed-slow: 0.8s;
	@transition-easing: cubic-bezier(0.645, 0.045, 0.355, 1);

	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.background--outer {
		position: relative;

		.image {
			z-index: @zindex-image;
			inset-inline: 0;
			background-repeat: no-repeat;
			transition: transform @transition-speed linear, height @transition-speed linear;
			background-size: cover;
			max-height: 60vh;
			

			@media (min-width: @screen-sm-min) { // 767px
				background-size: cover;
				min-height: 50vh;
				background-position: 50% 50%;
			}

			@media screen and (min-width: @screen-sm-min) and (max-width: @screen-lg-min) { // between 767 and 1279
				background-size: cover;
				max-height: auto;
			}

			&--animated,
			&--static {
				transform: translate3d(0, 0, 0) scale(1);

				@media (min-width: @screen-md-min) {
					transform: translate3d(0, -1.5%, 0) scale(1.15);
				}

				@media (min-width: @screen-lg-min) {
					transform: translate3d(0, 0, 0) scale(1.05);
				}
			}

			&--static {
				transition: none;
			}
		}

		.focus {

			&--left {
				background-position: calc(100% - 95%) 50%;
			}

			&--center {
				background-position: calc(100% - 50%) 50%;
			}

			&--right {
				background-position: calc(100% - 5%) 50%;
			}
		}

	}

	.content--outer {
		background-color: #fff;
		z-index: @zindex-content;
		width: 100%;
		opacity: 1;

		.content {
			width: auto;
			height: 100%;
			position: relative;
			z-index: @zindex-content;
			max-width: 1200px;
			box-sizing: content-box;
			margin: 0 auto;
			display: grid;
			grid-template-columns: repeat(12, minmax(0, 1fr));
			grid-gap: @spacing-16px;
			padding: 0 @spacing-16px;
			transition: color @transition-speed @transition-easing;

			@media (min-width: @screen-sm-min) {
				padding: 0 @spacing-24px;
				grid-gap: @spacing-24px;
			}

			@media (min-width: @screen-md-min) {
				padding: 0 @spacing-40px;
			}

			@media (prefers-reduced-motion: reduce) {
				transition: none;
			}

			&--dark {
				color: @token-color-colour-background-primary-dark;
			}

			&--animated,
			&--static {
				// .. but we do still want this colour to change between light/dark modes!
				color: var(--token-color-colour-text-primary);
			}

			&--static {
				transition: none;
			}
		}
	}

	.content-inner {
		grid-column: 1 / span 12;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0 0 @spacing-48px 0;
		max-width: 420px;
		width: 100%;

		@media (min-width: @screen-sm-min) {
			max-width: none;
			grid-column: 1 / span 6;
		}

		@media (min-width: @screen-lg-min) {
			grid-column: 1 / span 6;
		}
	}

	.title {
		margin: 0 0 @spacing-8px 0;
	}

	.buttons {
		margin: @spacing-24px 0 0 0;
		display: flex;
		flex-direction: column;
		gap: @spacing-8px;

		@media (min-width: @screen-sm-min) {
			flex-direction: row;
		}
	}

	.tailfin {
		color: var(--token-color-colour-background-primary);
		transform: translate(-54%, 0);

		@media (min-width: @screen-sm-min) {
			transform: translate(-62%, 0);
		}

		@media (min-width: @screen-lg-min) {
			transform: translate(-62%, 0);
		}

		&--front {
			width: 1100px;
			height: 225px;
			margin: 0 0 -15px 0px;

			@media (min-width: @screen-sm-min) { // 767px
				width: 1300px;
				height: 260px;
			}

			@media (min-width: @screen-md-min) { // 991px
				width: 1590px;
				height: 300px;
				margin: 0 0 -5px 0px;
			}

			@media (min-width: @screen-lg-min) { // 1279px
				width: 1745px;
				height: 326px;
			}
		}

		&--rear {
			opacity: 0.4;
			width: 1200px;
			height: 240px;
			margin: 0 0 -11px -3px;

			@media (min-width: @screen-sm-min) { // 767px
				width: 1320px;
				height: 260px;
			}

			@media (min-width: @screen-md-min) { // 991px
				width: 1730px;
				height: 330px;
				margin: 0 0 -5px -3px;
			}

			@media (min-width: @screen-lg-min) { // 1279px
				width: 1900px;
				height: 362px;
			}
		}
	}

	.tailfin-wrap {
		position: absolute;
		bottom: -1px;
		inset-inline-start: 0;
		inset-inline-start: 50%;
		user-select: none;
		pointer-events: none;
		z-index: @zindex-tailfin;
		display: flex;
		align-items: flex-end;
		transform: rotateX(78deg) scaleX(1) translate(0px, 5px);
		transform-origin: 50% 100%;
		opacity: 1;
		transition: transform @transition-speed @transition-easing;

		&:dir(rtl) {
			transform: rotateX(90deg) scaleX(-1);
		}

		&--animated,
		&--static {
			transform: rotateX(55deg) scaleX(1) translate(0px, 0px);
			opacity: 1;

			&:dir(rtl) {
				transform: rotateX(55deg) scaleX(-1);
			}

			&:has(.tailfin--front) {
				transform: rotateX(60deg) scaleX(1) translate(0px, 0px);

				@media (min-width: @screen-sm-min) {
					transform: rotateX(60deg) scaleX(1) translate(0px, 0px);
				}
			}
		}

		&--static {
			transition: none;
		}

		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}
	}
}
