@import (reference) '../../../styles/globals.less';

.hotels-map {
    border: solid 1px var(--token-color-neutral-n30);
    width: 100%;
    height: 640px;

    & div[data-testid*='image-component'] img {
        width: 100%;
    }

    & article[data-testid*='hotel-card-component'] div:nth-child(2) div:nth-child(3) {
        flex-direction: column;
        gap: @spacing-8px;

        @media (min-width: 460px) {
            flex-direction: row;
        }
    }
}