@import (reference) '../../../styles/globals.less';

.grid-layout {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-row-gap: @spacing-16px;

  @media (min-width: @screen-sm-min) {
    grid-row-gap: @spacing-24px;
  }
}

.padded-width {
  max-width: @screen-xl;
  margin: 0 auto;
}

.vertical-padding-top {
  padding-top: @spacing-48px;
  @media (min-width: @screen-sm-min) {
    padding-top: @spacing-64px;
  }

  @media (min-width: @screen-md-min) {
    padding-top: @spacing-96px;
  }
}

.vertical-padding-bottom {
  padding-bottom: @spacing-48px;
  @media (min-width: @screen-sm-min) {
    padding-bottom: @spacing-64px;
  }

  @media (min-width: @screen-md-min) {
    padding-bottom: @spacing-96px;
  }
}
