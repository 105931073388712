@import (reference) '../../../styles/globals.less';

.heading-component {
  text-align: left;
  margin-bottom: 16px;

  &.align_center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  &.align_right {
    text-align: right;
  }
}
