@import (reference) '../../../styles/globals.less';

.tabs {
  div[data-testid='tabs__content'] {
    padding-top: @spacing-24px;

    @media (min-width: @screen-sm-min) {
      padding-top: @spacing-32px;
    }
  }
}
