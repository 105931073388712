@import (reference) '../../../styles/globals.less';

.countdown-banner {
  background: var(--token-color-brand-secondary-80);
  color: var(--token-color-colour-text-inverted-primary);
  height: @spacing-56px + @spacing-8px;

  &__link {
    cursor: pointer;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--token-color-colour-text-inverted-primary);
    text-decoration: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    padding: 0 @spacing-4px;
  }

  &__inner {
    position: relative;
    height: @spacing-56px;
    transform-style: preserve-3d;
    transition: transform @transition-speed @transition-easing;

    &--step-1 {
      transform: rotateX(120deg) translateY(-50%);
      transition: none;
    }

    &--step-2 {
      transform: rotateX(0) translateY(0);
    }

    &--step-3 {
      transform: rotateX(-120deg) translateY(50%);
    }

    &--reset {
      transform: rotateX(-240deg) translateY(-50%);
    }

    @media (min-width: @screen-sm-min) {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: none;
    }
  }

  &__icon {
    margin: 0 @spacing-12px 0 0;
  }

  &__frame {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;

    &:nth-child(1) {
      top: -100%;
      transform-origin: 50% 100%;
      transform: rotateX(-120deg);

      @media (min-width: @screen-sm-min) {
        top: auto;
        transform-origin: unset;
        transform: none;
      }
    }

    &:nth-child(2) {
      top: 0;
      transform: rotateX(0);

      @media (min-width: @screen-sm-min) {
        top: auto;
        transform-origin: unset;
        transform: none;
      }
    }

    &:nth-child(3) {
      top: 100%;
      transform-origin: 50% 0%;
      transform: rotateX(120deg);

      @media (min-width: @screen-sm-min) {
        top: auto;
        transform-origin: unset;
        transform: none;
      }
    }

    @media (min-width: @screen-sm-min) {
      position: static;
      justify-content: flex-start;
      transition: none;
      width: auto;
    }
  }
}

.countdown-clock {
  @media (min-width: @screen-sm-min) {
    margin: 0 @spacing-8px;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }

  &__panel {
    background: var(--token-color-colour-background-primary);
    color: var(--token-color-colour-text-primary);
    text-align: center;
    height: @spacing-32px;
    width: @spacing-48px;
    padding: @spacing-2px 0 0 0;
    border-radius: @spacing-4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform @transition-speed @transition-easing;
    transform: rotateX(0);

    & + & {
      margin: 0 0 0 @spacing-8px;
    }

    span {
      display: block;
      line-height: 0.95;
    }

    &--disabled {
      transform: rotateX(90deg);
    }

    each(range(0, 4, 1), {
        &:nth-child(@{index}) {
          transition-delay: (@transition-speed * (@index /2));
        }
      });
  }
}
