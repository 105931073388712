@import (reference) '../../../styles/globals.less';
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.article-card {
  display: grid;
  padding: @spacing-16px;
  border-radius: @spacing-4px;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--token-color-neutral-n20);
  text-decoration: none;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 300ms forwards;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
  outline-offset: @spacing-2px;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease;
    z-index: -1;
  }

  .article-card:hover &__background,
  .article-card:focus &__background {
    transform: scale(1.1);
  }

  article {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &:hover {
    box-shadow: 0px 0px 5px 0px #00000033;
  }

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: @spacing-48px @spacing-16px @spacing-16px;
    background: linear-gradient(
      179deg,
      rgba(3, 12, 22, 0) 18.95%,
      rgba(3, 12, 22, 0.48) 38.92%,
      rgba(3, 12, 22, 0.64) 65.24%,
      rgba(3, 12, 22, 0.8) 98.77%
    );
  }

  &__title h3 {
    color: var(--token-color-neutral-n0);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    font-style: normal;
    font-weight: 350;
    line-height: @spacing-24px;
  }

  &__sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
