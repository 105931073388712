@import (reference) '../../../styles/globals.less';

.link-banner-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.link-banner {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: @spacing-12px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 3px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__link-item {
    display: inline-flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: @spacing-4px;
    padding: @spacing-12px @spacing-24px @spacing-12px @spacing-16px;
    text-decoration: none;
    color: #333;
    justify-content: center;
    border-radius: @spacing-8px;
    background: #f5f5f6;
    cursor: pointer;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s forwards;
    gap: 12px;
  }

  &__link-icon {
    margin-right: 0.5rem;
  }

  &__link-label {
    color: #030c16;
    font-size: @spacing-12px;
    font-style: normal;
    font-weight: 325;
    line-height: @spacing-24px;
  }

  &__visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  &__button {
    color: white;
    border: none;
    position: absolute;
    z-index: 100;
    opacity: 0;
    animation: buttonFadeIn 0.5s forwards;
    width: 80px;
    height: 100%;

    @media screen and (max-width: 768px) {
      display: none !important;
    }

    button {
      border: 1px solid #030c16;
      border-radius: 20px;
      margin-top: 3px;
    }
  }

  &__button_right {
    right: 0;
    transform: translateX(10px);
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 50.86%,
      rgba(255, 255, 255, 0) 100%
    );

    button {
      position: absolute;
      right: 0;
    }
  }

  &__button_left {
    left: 0;
    transform: translateX(-10px);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 50.86%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttonFadeIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.loaded .link-banner__link-item {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
