@import (reference) '../../../styles/globals.less';
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.alert-card {
  display: grid;
  padding: @spacing-16px;
  border-radius: @spacing-4px;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--token-color-neutral-n0);
  text-decoration: none;
  opacity: 0;
  animation: fadeIn 300ms forwards;
  outline-offset: @spacing-2px;

  & article {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &:hover {
    box-shadow: 0px 0px 5px 0px #00000033;
  }

  &__accent {
    border: @spacing-2px solid var(--token-color-colour-orange-60);
    border-radius: @spacing-2px;
    position: absolute;
    top: @spacing-12px;
    bottom: @spacing-12px;
    left: 15px;
  }

  &__icon {
    margin-left: @spacing-16px;
    margin-top: @spacing-12px;
    grid-row: 1;
    color: var(--token-color-colour-orange-60);
  }

  &__content {
    margin-top: @spacing-16px;
    margin-left: @spacing-16px;
    margin-bottom: @spacing-8px;
    grid-row: 3;

    @media (min-width: 992px) {
      margin-top: 36px;
    }
  }

  &__footer {
    margin-left: @spacing-16px;
    grid-row: 2;
  }

  &__title h3 {
    color: var(--token-color-neutral-n100);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    font-style: normal;
    font-weight: 350;
    line-height: @spacing-24px;
  }

  &__last-updated {
    margin-top: @spacing-8px;
    color: var(--token-color-neutral-n60);
  }
}
